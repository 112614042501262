//
// CE Images Custom CSS
//

.ce-image {

}

.ce-image-images {

  // Grid 2
  &.grid-2 {
    display: flex;
    margin: -10px;
    overflow: hidden;

    figure {
      width: 50%;
      padding: 10px;
    }

  }


  // Grid 3
  &.grid-3 {

    display: flex;
    margin: -5px;
    overflow: hidden;

    figure {
      width: calc(100% / 3);
      padding: 5px;
    }

  }

}