//
// Sponsors CSS
//

.slider-element 					{max-width:660px;}
.slider-element .slider-padding 	{padding:20px;}
.slider-element .slider_wrap		{display:block;text-decoration:none;padding-top:60.5%;overflow:hidden;height:0;position:relative;}
.slider-element.mask .slider_wrap	{padding-top:57.9%;}

/* ************************ */
/* 		Datum 				*/
/* ************************ */
.slider-element .date 				{padding:0 0 20px 0;}

.slider-element .date .start-date,
.slider-element .date .end-date		{float:left;max-width:50px;}

.slider-element .date .date-trenner 			{display:block;padding:0 60px 0 0;margin:0 -20px;float:left;transform: rotate(-90deg);font-size:1.5rem;}
.slider-element .date.nur_bis .date-trenner 	{margin:0 -20px 0 -35px;}

/* ************************ */
/* 		Ueberschrift 		*/
/* ************************ */
 .slider-element h2,
 .slider-element h3					{margin:0;padding:0;}
 .slider-element .headline h3		{padding-top:5px;} 		/* nur wenn auch h2 existiert */
 
 .slider-element h2.size_klein,
 .slider-element h3.size_klein		{font-size:1rem}
 
 .slider-element .hbox.rahmen_0		{border:3px solid #000;padding:10px 10px 5px 10px;}
 
 .slider-element .hbox.positionbottom_1 	{position:absolute;bottom:20px;}
 
/* ************************ */
/* 		Text & Bild 		*/
/* ************************ */
.slider-element .text,
.slider-element .image 				{position:absolute;width:50%;} 
.slider-element .image img 			{width:100%;height:auto;}

.slider-element .text 				{z-index:10;height:100%;} 
.slider-element .image 				{z-index:5;} 

/* Text links // Bild rechts */
.slider-element .img-text-position_0 .text 				{top:0;left:0;}
.slider-element .img-text-position_0 .image 			{top:0;right:0;}

.slider-element .img-text-position_0 .text .date,
.slider-element .img-text-position_0 .text .hbox 		{margin-right:20px;}
 
 .slider-element .img-text-position_0 .text .hbox.positionbottom_1 {right:20px;margin-left:20px;}

/* Text rechts // Bild links */
.slider-element .img-text-position_1 .text 				{top:0;right:0;}
.slider-element .img-text-position_1 .image 			{top:0;left:0;}

.slider-element .img-text-position_1 .text .date,
.slider-element .img-text-position_1 .text .hbox 		{margin-left:20px;}
 
 .slider-element .img-text-position_1 .text .hbox.positionbottom_1 {left:20px;margin-right:20px;}
	
/* Randabfallend (Bild oder weisser Hintergrund) */
.slider-element.slider_1001 .slider-padding,
.slider-element.slider_0 .slider-padding,
.slider-element.slider_1013 .slider-padding,
.slider-element.slider_1014 .slider-padding 		{padding:0;}

.slider-element.slider_1001 .slider_wrap,
.slider-element.slider_0 .slider_wrap,
.slider-element.slider_1013 .slider_wrap,
.slider-element.slider_1014 .slider_wrap			{padding-top:60.5%;}
 
.slider-element.slider_1001 .hbox.positionbottom_1	 {bottom:0;}
.slider-element.slider_1001 .img-text-position_0 .text .hbox.positionbottom_1 {margin-right:20px;margin-left:0;right:auto;left:0;}
.slider-element.slider_1001 .img-text-position_1 .text .hbox.positionbottom_1 {margin-right:0;margin-left:20px;rigth:0;left:auto;}

/* Bild als Hintergrund */
.slider-element.slider_1013,
.slider-element.slider_1014 						{position:relative;}

.slider-element.slider_1013 .slider_wrap,
.slider-element.slider_1014 .slider_wrap 			{position:inherit;}

.slider-element.slider_1013  .image .img_inner,
.slider-element.slider_1014  .image .img_inner 		{margin:0;}

.slider-element.slider_1013 .image,
.slider-element.slider_1014 .image 					{top:0;left:0;width:100%;height:auto;}

.slider-element.slider_1013 .text,
.slider-element.slider_1014 .text 					{padding:20px;}


/* ************************ */
/* 		Hintergrund 		*/
/* ************************ */
/* Hintergrundfarbe */
/* 1001 = weisser Hntergrund 		*/ .slider_1001, .slider_0 {background:#fff;}
/* 1002 = grauer Hintergrund 		*/ .slider_1002 {background:#bfbfbf;}
/* 1003 = schwarzer Hintergrund 	*/ .slider_1003 {background:#000;}
/* 1004 = gelber Hintergrund 		*/ .slider_1004 {background:#fffbcc;}
/* 1005 = hellblauer Hintergrund 	*/ .slider_1005 {background:#dbefee;}
/* 1006 = lila Hintergrund 			*/ .slider_1006 {background:#7277a6;}
/* 1007 = tuerkiser Hintergrund 	*/ .slider_1007 {background:#70ddc6;}
/* 1008 = pinker Hintergrund 		*/ .slider_1008 {background:#ed7198;}
/* 1009 = rosa Hintergrund 			*/ .slider_1009 {background:#fbe2e1;}
/* 1010 = oranger Hintergrund 		*/ .slider_1010 {background:#fb9650;}
/* 1011 = dunkelgelber Hintergrund 	*/ .slider_1011 {background:#c6be11;}
/* 1012 = blauer Hintergrund 		*/ .slider_1012 {background:#a0cdd9;}

/* Schriftfarbe */
.slider_1010 .hbox.hbox.rahmen_0,
.slider_1008 .hbox.hbox.rahmen_0,
.slider_1006 .hbox.hbox.rahmen_0,
.slider_1003 .hbox.hbox.rahmen_0,
.slider_1014 .hbox.hbox.rahmen_0	{border-color:#fff;}
.slider_1010,
.slider_1008,
.slider_1006,
.slider_1003,
.slider_1014,
.slider_1010 a,
.slider_1008 a,
.slider_1006 a,
.slider_1003 a,
.slider_1014 a 		{color:#fff !important;}


@media screen and (max-width: $size-SM2) {
 .slider-element h2,
 .slider-element h3		 {font-size:15px;}
}


@media screen and (max-width: $size-S) {
 //.slider-element.mask .slider_wrap {padding-top:120%;}

 .slider-element .text {width:100%;height:100%;}
 .slider-element .image {width:70%;}

 .slider-element .text .date 						{width:25%;margin:0!important;}
 .slider-element .img-text-position_1 .text .date 	{float:right;}
 .slider-element .img-text-position_0 .text .date 	{float:left;}
 .slider-element .date .start-date,
 .slider-element .date .end-date 					{width:40px;float:none;margin:0 auto;}
 .slider-element .date .date-trenner 				{transform:none;float:none;text-align:center;padding:5px 10px 5px 0;}
 .slider-element .img-text-position_1 .date .date-trenner {padding:5px 10px 5px 0;}
 .slider-element .img-text-position_0 .date .date-trenner {padding:5px 0 5px 10px;}

 .slider-element .img-text-position_1 .text .hbox,
 .slider-element .img-text-position_0 .text .hbox{clear:both;margin:0;position:absolute;bottom:0;left:0;}

 .slider_1014 .hbox.hbox.rahmen_0	{border-color:#000;color:#000 !important;}
}
