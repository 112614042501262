//
// Custom CE Text With Images CSS
//

.ce-text-with-images {

  overflow: hidden;

  .ce-text-with-images-wrap {
    float: left;
    clear: left;
    width: 235px;
  }

  .bodytext {
    float: right;
    padding-left: 20px;
    width: 425px;
  }

  .header-content {
    display: inline-block;
    position: relative;
    background-color: $grey;
    float: right;
    width: 405px;
    margin: 55px 0 20px 20px;
    //min-height: 140px;
    overflow: hidden;
    padding: 20px;

    .header-topic {
      font-family: Montserrat, sans-serif;
      @include font-size(14);
      text-transform: uppercase;
    }

    h1 {
      margin: 0;
    }
    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
    }
  }

  figure {
  	a,
	a:link,
	a:visited,
	a:active,
	a:hover,

    img {
      display: block;
      margin-bottom: 10px;
    }
    /*
	span {
		display:block;
		line-height:0;
		padding:0 0 10px 0;
	

		img {
		  display: block;
		  float:right;
		}
	}*/
  }

  figcaption {
    font-family: Montserrat, sans-serif;
    @include font-size(10);
    line-height: 15px;
    text-align: right;
	padding: 0 0 20px 0;
	text-transform: uppercase;
  }

  @media screen and (max-width: $size-L) {

    .header-content {
      display: block;
      width: auto;
      float: none;
      margin: 0 0 15px 0;
    }

    .ce-text-with-images-wrap {
      display: block;
      width: auto;
      float: none;
      padding: 0;
      margin-bottom: 15px;
    }

    .bodytext {
      display: block;
      width: auto;
      float: none;
      padding: 0;
    }

    figcaption {
      text-align: left;
    }

  }

}

.content-deco {
  float: left;
  margin: 0;
  padding: 0 0 20px 126px;;
  width: 235px;

  .content-deco-box {
    background-color: $grey;
    height: 80px;
    width: 108px;
  }

  @media screen and (max-width: $size-L) {
    display: none;
  }

}

  @media screen and (max-width: $size-L) {
    section {padding-bottom:40px;}
	.ce-text-with-images figure a img,
	.ce-text-with-images figure a:link img,
	.ce-text-with-images figure a:visited img,
	.ce-text-with-images figure a:active img,
	.ce-text-with-images figure a:hover img,
	.ce-text-with-images figure span img {float:none;}
  }

section .content-deco .content-deco-box {background:none;}
section:first-child .content-deco .content-deco-box,
section.header-layout-type-4 .content-deco .content-deco-box {background:#d3d3d3;}
section.header-layout-type-4 {padding-top:40px;}


.header-layout-type-3 {
  .content-deco-box {
    background-color: transparent;
  }
}


/* Aussnahmen fuer Kuenstler */
.kuenstler > .content-deco {display:none;}
.kuenstler > .ce-text-with-images-wrap figure:first-child {float: left;margin: 0;padding: 0 0 10px 126px;width: 235px;}
/* nicht aber fuer weitere Zustiftungen */
#page-56 .kuenstler > .content-deco {display:block;}
#page-56 .kuenstler > .ce-text-with-images-wrap figure:first-child {float: none;padding: 0;width: auto;}

@media screen and (max-width: $size-L) {
.kuenstler > .ce-text-with-images-wrap figure:first-child {float:none;margin: 0;padding: 0 0 0 0;width: auto;}
}

/* Galerie Ansicht mit Slider */
/*
.gallery-slider,
.gallery-slider .slick-list 					{position:relative;height:700px;}

.gallery-slider button 							{position:absolute;bottom:0;top:auto;background-size:auto 100%;width:20px;height:20px;}
.gallery-slider button.slick-prev				{left:0;}
.gallery-slider button.slick-next				{right:0;}

.gallery-slider ul.slick-dots 					{margin:0;left:10%;width:80%;}
.gallery-slider ul.slick-dots li 				{display:none;margin:0;}
.gallery-slider ul.slick-dots li.slick-active,
.gallery-slider ul.slick-dots li.counter		{display:inline-block;}

@media screen and (min-width: $size-L) {
	.gallery-slider .slick-slide 					{height:340px;width:225px !important;margin-left:10px;}
	.gallery-slider .slick-slide:nth-child(odd) 	{}
	.gallery-slider .slick-slide:nth-child(even) 	{position:relative;top:340px;margin-left:-225px;}
}
@media screen and (max-width: $size-L) {
	.gallery-slider,
	.slick-list 									{height:auto;}
	.ce-text-with-images .gallery-slider.ce-text-with-images-wrap {padding-bottom:35px;}

	.gallery-slider .slick-slide 					{}
	.gallery-slider .slick-slide:nth-child(even) 	{}
	.gallery-slider .slick-slide:nth-child(odd) a 	{padding-right:10px;}
	.gallery-slider .slick-slide:nth-child(even) a 	{padding-left:10px;}
}
@media screen and (max-width: $size-S) {
	.gallery-slider .slick-slide:nth-child(odd) a,
	.gallery-slider .slick-slide:nth-child(even) a 	{padding:0 0 10px 0;}
}
*/

/* Galerie Ansicht NEU */

.gallery .content-deco 									{width:auto;padding-left:0;}
.gallery.ce-text-with-images .header-content			{float:left;}
.gallery.ce-text-with-images .bodytext 					{float:none;clear:both;width:533px;padding:0 0 20px 126px;}

.gallery.ce-text-with-images .ce-text-with-images-wrap 								{padding:0 0 40px 126px;width:100%;margin-right:-20px;display: flex;flex-flow: row wrap;}
.gallery.ce-text-with-images .ce-text-with-images-wrap figure 						{float:left;width:25%;position:relative;}
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .placeholder 			{padding-top:95.5%;}
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background 			{top:0;left:0;width:100%;height:100%;padding:0 20px 20px 0;}
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div 		{display:table;height:100%;width:100%; table-layout: fixed; }
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div a 	{background:#D3D3D3;display:block;width:100%;height:100%;padding:10px;display:table-cell;vertical-align: middle;}
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div a img	{max-width:100%;max-height:220px;width:auto;height:auto;margin:0 auto;float:none;}

.gallery.ce-text-with-images .ce-text-with-images-wrap figure.links a 		{background:#D3D3D3;display:inline-block;width:auto;position:relative;line-height:inherit;text-decoration:none;padding:5px 10px;margin:0 0 10px 0 }

@media screen and (max-width: 1500px) {
.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div a img	{max-height:150px;}
}

@media screen and (max-width: $size-XXL) {
	.gallery.ce-text-with-images .bodytext 									{width:100%;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure 			{width: calc(100% / 3);}
}

@media screen and (max-width: $size-XL) {
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure 			{width:50%;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap 					{padding:0 0 40px 0;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div a img	{max-height:220px;}
}

@media screen and (max-width: $size-L) {
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure 			{width:50%;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background div a img	{max-height:220px;}
}

@media screen and (max-width: $size-M) {
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure 					{width:100%;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure .placeholder 		{padding-top:0;}
	.gallery.ce-text-with-images .ce-text-with-images-wrap figure .background		{position:relative;height:auto;padding-right:0;}
}


.gallery-download-links {

  padding: 0 0 0 126px;

  a {
    display: block;
    background: #D3D3D3;
    padding: 10px;
    margin-bottom: 20px;
    max-width: 305px;
    text-decoration: none;
  }
}