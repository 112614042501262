//
// Sponsors CSS
//

.supporter-bar {

  .ce-image-grid {
    margin: auto;
    padding: 0;

    max-width: 660px;

  }


  h3 {
    @include font-size(14);
    text-transform: none;
  }

  .ce-image-grid-sponsors-wrap {

    overflow: hidden;
    text-align: center;

    figure {
      display: inline-block;
    }

  }

}