//
// Nav Main CSS
//

.nav-main {
  position: relative;
  max-width: $maxPageWidth;
  margin: auto;
  height: 100px;

  ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    text-align: center;

    li {
      display: inline-block;
      font-family: Montserrat, sans-serif;
      @include font-size(18);
      font-weight: 400;
      line-height: 25px;
      margin-right: 20px;
      text-align: center;
      text-transform: uppercase;
	  @media screen and (max-width: $size-SM) {
      margin-right: 60px;
	  }

      a {
        color: $black;
        text-decoration: none;

        &.active {
          border-bottom: 1px solid $black;
          font-weight: 700;
        }
      }
    }
  }
  
  @media screen and (min-width: $size-L) {
  > ul > li:last-child {
  	border-left:1px solid #000;
	padding-left: 20px;
  }
  
  .nav-main ul li ul li:first-child {
  padding-left: 16px;
  }
  }

  > ul {
    height: 80px;
    padding-top: 10px;

    > li {

    }
  }

  ul li ul {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    display: none;

    li {
      font-size: 14px;
      line-height: 22px;
      padding-right: 16px;
      margin: 0;
    }
  }


  ul li.open ul {
    display: block;
  }

  @media screen and (max-width: $size-L) {
    display: none;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: auto;
    background: $white;
    padding-bottom: 10px;

    ul {
      height: auto;

      li {
        display: block;
        float: none;
        width: auto;
        text-align: center;
        padding: 5px 0;

        ul {
          position: relative;
          left: auto;
          top: auto;
          padding-top: 10px;
          li {
            padding: 5px 0;
          }
        }
      }
    }

  }

  @media screen and (min-width: $size-L) {
    display: block !important;
  }

}


