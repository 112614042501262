//
// Footer CSS


body > footer {

  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 200px;
  width: 100%;
  text-align: center;
	z-index: 99999;
  
  .footer-inner {
  
  	  position:relative;
	  
	  .supporter-bar {
	  	position: absolute; 
		left:40px;
		bottom:0
	  }

	  ul {

		margin: 0;
		padding: 10px 0;

		li {
		  display: inline-block;
		  font-family: Montserrat, sans-serif;
		  font-weight: 400;
		  letter-spacing: 1px;
		  padding-left: 15px;

		  &:first-child {
			padding-left: 0;
		  }

		  a {
			color: $black;
			text-decoration: none;
			text-transform: uppercase;
		  }
		}

	  }
  }


}

@media screen and (min-width: $size-L) {
	body > footer 	{position:fixed;background:#fff;height:100px;padding:20px 0 0 0;}
}

@media screen and (max-width: $size-XXL) {
body > footer .footer-inner .supporter-bar {
	bottom:-90px;width:100%;left:0;text-align:left;padding:0 0 20px 20px;
}
}

@media screen and (max-width: $size-S) {
body > footer .footer-inner ul li {display:block;padding:0 0 5px 0;}
}