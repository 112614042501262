
/* CSS Document */


// Allgemein
.clear 			{clear:both;}

// Trenner
hr 				{border:none;margin:0;padding:0;padding-top:40px;}
hr.layout_302	{padding-top:80px;}
hr.layout_303	{padding-top:120px;}


// RTE muss doppelt gepflegt werden rte css fuer config notwendig
.zwischenueberschrift 	{font-weight:bold;text-transform:uppercase;}
.align-right 			{text-align:right;}
.align-left 			{text-align:left;}
.align-center 			{text-align:center;}
strong 					{text-transform:uppercase;}


// Navigation fuer Kuenstler
.ce-menu.ce-menu-1 						{display:block;clear:both;background:url(../../../../../../fileadmin/user_upload/Logos/SKK_Logo_HG-Kuenstler.svg) no-repeat center center;background-size:100% auto;min-height:620px;margin:0;padding:0;}
.ce-menu.ce-menu-1 li 					{display:block;text-align:center;padding:5px 0;} 

.ce-menu.ce-menu-1 li.cycle_1,
.ce-menu.ce-menu-1 li.cycle_3 			{float:left;width:50%;} 
.ce-menu.ce-menu-1 li.cycle_1.li_0,				 				
.ce-menu.ce-menu-1 li.cycle_2 			{clear:both;width:100%;} 

.ce-menu.ce-menu-1 li a,
.ce-menu.ce-menu-1 li a:link,
.ce-menu.ce-menu-1 li a:visited,
.ce-menu.ce-menu-1 li a:active,
.ce-menu.ce-menu-1 li a:hover 			{text-decoration:none;font-family: Montserrat, sans-serif;font-weight:400;font-size:16px;text-transform: uppercase;} 
.ce-menu.ce-menu-1 li a span.lastname	{font-weight:700;font-size:34px;} 

@media screen and (max-width: $size-SM) {
.ce-menu.ce-menu-1 li a,
.ce-menu.ce-menu-1 li a:link,
.ce-menu.ce-menu-1 li a:visited,
.ce-menu.ce-menu-1 li a:active,
.ce-menu.ce-menu-1 li a:hover 			{font-size:14px;} 
.ce-menu.ce-menu-1 li a span.lastname	{font-size:25px;} 
}

@media screen and (max-width: $size-S) {
.ce-menu.ce-menu-1 li.cycle_1,
.ce-menu.ce-menu-1 li.cycle_3 			{clear:both;width:100%;} 
}


// Startseite SKK Werke
#page-36 main 							{max-width:950px;}

#page-36 .ce-image-grid 				{float:left;width:auto;margin:0;padding:0;}
#page-36 .ce-image-grid div				{float:none;margin:0;padding:0;}
#page-36 .ce-image-grid div	figure		{float:none;display:block;line-height:0;margin:0;padding:0 10px 10px 0;width:100%;}
#page-36 .ce-image-grid div	figure a	{float:none;display:block;line-height:0;margin:0;padding:0;clear:both;}
#page-36 .ce-image-grid div	figure a img{max-width:100%;height:auto;}

#page-36 .ce-image-grid:nth-child(1) div figure span img,
#page-36 .ce-image-grid:nth-child(1) div figure a img,
#page-36 .ce-image-grid:nth-child(2) div figure span img,
#page-36 .ce-image-grid:nth-child(2) div figure a img {float:right;}

#page-36 .ce-image-grid:nth-child(1) 	{max-width:200px;width:21%;/*padding-top:213px;*/padding-top:22.00%;}
#page-36 .ce-image-grid:nth-child(2) 	{max-width:200px;width:21%;/*padding-top:100px;*/padding-top:10.63%;}
#page-36 .ce-image-grid:nth-child(3) 	{max-width:190px;width:20%;padding-top:0;}
#page-36 .ce-image-grid:nth-child(4) 	{max-width:195px;width:20.5%;/*padding-top:110px;*/padding-top:20.74%;}
#page-36 .ce-image-grid:nth-child(5) 	{max-width:155px;width:16.5%;/*padding-top:265px;*/padding-top:23.19%;}

@media screen and (max-width: $size-SM) {
#page-36 .ce-image-grid 				{float:none;width:100% !important;max-width:inherit !important;padding-top:0!important}
#page-36 .ce-image-grid div	figure		{padding:0 0 10px 0;}
#page-36 .ce-image-grid div figure a img {float:none !important;margin:0 auto;}
}


