


/* Formular */
form fieldset {margin:0;padding:0;border:none;}

.powermail_fieldwrap 							{padding:0 0 10px 0;}
.powermail_fieldwrap label						{text-align:right;padding:5px 0 0 0;}
.powermail_fieldwrap input[type="text"],
.powermail_fieldwrap input[type="email"],
.powermail_fieldwrap input[type="tel"],
.powermail_fieldwrap select  					{width:100%;border:1px solid $grey;padding:3px;height:26px;box-sizing: border-box;}
.powermail_fieldwrap select 					{border-radius:0;padding:0;height:26px;}
.powermail_fieldwrap input[type="submit"] 		{text-align:center;border:none;border-radius:0;background:$grey;padding:5px 20px;}

.powermail_fieldwrap ul.parsley-errors-list 	{padding:0;margin:0;}
.powermail_fieldwrap ul.parsley-errors-list li	{padding:0;list-style:none;font-size:0.8em;}


/* Login */
.frame-type-felogin_login {
	form {

		legend {
			display: none;
		}

		fieldset {
			label {
				display: flex;
				justify-content: right;
			}
		}

		input[type="text"],
		input[type="password"] 	{
			border: 1px solid $grey;
			padding: 3px;
			height: 26px;
			margin: 0 0 10px 20px;
			width: 400px;
		}

		input[type="submit"] {
			text-align: center;
			border: none;
			border-radius: 0;
			background: $grey;
			padding: 5px 20px;
			margin-left: 260px;
		}


		@media screen and (max-width: $size-L) {
			input[type="text"],
			input[type="password"] 	{
				width: 70%;
			}

			input[type="submit"] {
				margin-left: 30%;
				width: 70%;
			}
		}
	}
}


/**/
.powermail_create p 								{width:425px !important;padding-left:20px;float:right;}

.powermail_create table 							{width:100%;}
.powermail_create table td.powermail_all_label 		{text-align:right;} 
.powermail_create table td.powermail_all_value 		{width:425px !important;padding-left:20px;} 

 @media screen and (max-width: $size-S) {
	.powermail_create table td 							{diplay:block;width:100% !important;}
	.powermail_create table td.powermail_all_label 		{text-align:left;} 
	.powermail_create table td.powermail_all_value 		{padding:0 0 5px 0;} 
 }

.tx-powermail {
	h3,
	legend {
		display: none;
	}
}

.mfp-content {
	.powermail_fieldwrap_type_text {
		@include font-size(12);
	}
}
