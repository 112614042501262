//
// News CSS
//


// list View
.news-list-view {

  a {
    text-decoration: none;
  }

  .news-list-item-content {
    overflow: hidden;
    margin-bottom: 30px;
    background: $grey;
    min-height: 140px;

    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
    }
  }
  
  .date-trenner {
  	margin:0;
	padding:10px;
  }


  .news-list-text {
    float: left;
    padding: 20px;
	box-sizing: border-box;
	width:82%;
  }

  .news-list-info {
    font-family: Montserrat, sans-serif;
    @include font-size(15);
    text-transform: uppercase;
    margin-bottom: 10px;
  }


  .article-type-slider {

	.news-list-item-content {
	  height: 210px;
	  padding-left:20px;
	  padding-right:20px;
	}

	.news-list-date {
	width:25%;
	}

	.news-list-text {
	width:75%;
	}
  
  }

}

// Datum im Slider
@media screen and (max-width: $size-M) {
  .news-list-view .article-type-slider .news-list-date {width:30%;}
  .news-list-view .article-type-slider .news-list-text {width:60%;}
}

@media screen and (max-width: $size-SM) {
  .news-list-view .article-type-slider .news-list-date {width:100%;}
  .news-list-view .article-type-slider .news-list-date > div {width:30%;float:left;}
  .news-list-view .article-type-slider .news-list-date > p 	{width:50px;text-align:center;float:left;}
  
  .news-list-view .article-type-slider .news-list-text {width:100%;}
  
  .news-list-view .article-type-slider .news-list-item-content {height:260px;}
}

@media screen and (max-width: $size-S) {
	.news .slick-slider {width:100%;}
  	.news-list-view .article-type-slider .news-list-date > div {width:34%;}
}


  .news-list-date {
    float: left;
    padding: 20px;
    width: 115px;
    font-family: Montserrat, sans-serif;
	box-sizing: border-box;
	width:18%;
  }

  .news-list-date-day {
    @include font-size(24);
  }
  .news-list-date-month {
    @include font-size(24);
    float: left;
  }
  .news-list-date-weekday {
    float: right;
    transform: rotate(270deg);
    //width: 19px;
    width: 28px;
    writing-mode: horizontal-tb;
  }


// Datum im Kalender
@media screen and (max-width: $size-M) {
  .news-list-date {width:20%;}
  .news-list-view .news-list-text {width:80%;}
}

@media screen and (max-width: $size-SM) {
  .news-list-date {width:27%;}
  .news-list-view .news-list-text {width:73%;}
}

@media screen and (max-width: $size-S) {
  .news-list-date {width:100%;}
  .news-list-date > div {width:30%;float:left;}
  .news-list-date > p 	{width:50px;text-align:center;float:left;}
  
  .news-list-view .news-list-text {width:100%;}
  .news-list-view .news-list-item-content h2,
  .news-list-view .news-list-item-content h3 {font-size:15px;}
}



// detail View
.news-single {


  .footer {
    margin: 20px 0 0 255px;
  }

  .social-media {
    margin-top: 15px;
    div {
      margin-bottom: 10px;
    }
  }

  .news-backlink-wrap {
    display: none;
  }

  @media screen and (max-width: $size-L) {
    .footer {
      margin: 0;
    }
  }

}