//
// CE Video CSS
//

.ce-video {
  clear: both;
  padding-top: 20px;

}

.video-area {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .external-video-consent {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($white, .85);
    padding: 20px 30px;
    display: none;
    @include font-size(14);

    @media only screen and (max-width: $size-SM) {
      @include font-size(12);
    }

    &.show {
      display: block;
    }

    a {
      color: $black;
    }

    .btn-group {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }

    button {
      border: none;
      background: rgba($grey-mid, .7);
      color: $white;
      border-radius: 3px;
      padding: 6px 15px;

      &:hover {
        background: rgba($grey-mid, 1);
      }
    }
  }

}

.video-keep-data-privacy {
  display: block;
  position: relative;

  img {
    display: block;
    max-width: none;
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../Images/youtube-play-button.svg") no-repeat center center;
    background-size: 80px;
    opacity: .8;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

}

.save-youtube-consent-confirm {
  padding-top: 6px;

  label {
    padding-left: 6px;
  }
}