//
// Defaul Project Settings
//

$maxPageWidth: 1020px;
$maxContentWidth: 660px;
$baseFont: "Open Sans", Helvetica, sans-serif;


// General Breakpoints
$size-XXL: 1050px;
$size-XL: 840px;
$size-L: 720px;
$size-M: 650px;
$size-SM2: 600px;
$size-SM: 550px;
$size-S: 460px;