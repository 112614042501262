//
// Header CSS
//

.page > header {

  overflow: hidden;
  padding: 35px 40px;
  margin: auto;

  .header-title {
    position: relative;
    float: left;
    font-family: Montserrat, sans-serif;
    @include font-size(18);
    font-weight: 700;
    text-transform: uppercase;
    //width: 216px;

    a {
      text-decoration: none;
    }

    .header-title-small {
      font-weight: 400;
      @include font-size(16);
      text-transform: none;
    }
  }

  .logo {
    position: relative;
    float: right;
    width: 78px;
    z-index: 99;
  }

  .burger-icon {
    position: relative;
    float: right;
    width: 30px;
    margin: 10px 0 0 0;
    cursor: pointer;
    display: none;
    z-index: 99;

    div {
      background: $black;
      height: 2px;
      margin: 5px 0;
    }

  }


  @media screen and (max-width: $size-L) {

    display: flex;
    justify-content: space-between;


    .burger-icon {
      display: block;
      order: 3;
    }

    .header-title {
      padding-top: 18px;
      order: 2;
    }

    .logo {
      order: 1;
      margin-top: 10px;
    }

  }

  @media screen and (max-width: $size-SM) {

    padding: 35px 20px;

    .logo {
      width: 50px;
	  position: relative;
	  z-index:8;
    }

    .header-title {
      @include font-size(14);

      .header-title-small {
        @include font-size(12);
      }
    }
  }

}