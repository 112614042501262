//
// Newsletter Overlay CSS
//

// frame type 99 is our CE content to be displayed within an overlay
.frame-99 {
  display: none;

  &.show {
    display: block;
  }

  .powermail_fieldwrap_type_submit {
    margin-top: 15px;
  }

  .powermail_create p {
    width: auto;
    padding-left:0;
    float: none;
  }

  h3 {
    margin-top: 0;
    text-transform: none;
    margin-bottom: 10px;
  }

  h4 {
    margin-top: 0;
  }
}

.newsletter-overlay {

  .mfp-content {
    max-width: 400px;
    border: 1px solid $grey-mid;
    box-shadow: $grey-mid 10px 10px 10px;
    background: $white;
    padding: 30px;
  }
}