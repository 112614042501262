//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/fonts";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

// general

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 200px;
  font-family: $baseFont;
  @include font-size(15);
  color: $black;
  background: $white;
}

a {
  color: $black;
}

p {
  margin: 0 0 1em 0;
  line-height: 1.4;
  &:last-child {
  margin-bottom: 0;
}
}

h1 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 700;
  text-transform: uppercase;
}
h2 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 700;
  text-transform: uppercase;
}
h3 {
  font-family: Montserrat, sans-serif;
  @include font-size(25);
  font-weight: 400;
  text-transform: uppercase;
}

@media screen and (max-width: $size-S) {
  h1,
  h2,
  h3 {
    @include font-size(15);
  }
}


.page {
  position: relative;
  @include clearfix();
}

main {

  .ce-space-before-medium {
    margin-top: 40px;
  }

  .ce-space-after-medium {
    margin-bottom: 40px;
  }

  .bodytext,
  .news-list-view .bodytext {

    a {
      text-decoration: underline;
    }

  }

}


@import "header";
@import "nav-main";
@import "nav-language";
@import "mainpage";
@import "footer";
@import "formular";

@import "lib/slick_slider/base";

@import "ce-text-with-images";
@import "ce-image-grid";
@import "ce-video";
@import "ce-images";
@import "news";
@import "sponsors";

@import "slider";

@import "page";

@import "pv_cookie_consent";
@import "newsletter-overlay";