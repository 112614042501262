//
// Main CSS
//

main {

  max-width: $maxContentWidth;
  margin: 0 auto 0 auto;
  padding: 0 0 50px 0;
  @include clearfix;

  @media screen and (max-width: $size-L) {
    margin-top: 0;
    padding: 20px;
  }

}

/* Breites Layout */
.page-layout-1 main {
	max-width:inherit;
	padding-left:15%;
	padding-right:10%;
}

@media screen and (max-width: $size-XXL) {
	.page-layout-1 main {
	padding-left:10%;
	padding-right:7%;
	}
}

@media screen and (max-width: $size-XL) {
	.page-layout-1 main {
	padding-left:5%;
	padding-right:5%;
	}
}

@media screen and (max-width: $size-L) {
	.page-layout-1 main {
	padding-left:20px;
	padding-right:20px;
	}
}

@media screen and (max-width: $size-XXL) {

  .page-id-8,
  .page-id-1 {
    main {
      margin-top: 0;
    }
  }

}

